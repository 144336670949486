import React, { useEffect, useState } from 'react';

interface ErrorProps {
  onRetry?: () => void;
}

const funnyErrorMessages = [
  "Oops! Something went wrong. Did you try turning it off and on again?",
  "Yikes! This page is hiding... or maybe it just went to the movies!",
  "Well, this is awkward. Our code monkeys are on it!",
  "404: The page took a wrong turn at Albuquerque.",
  "Huh. That wasn’t supposed to happen. Blame it on the gremlins!",
  "Error 404: The requested page is taking a coffee break.",
  "Oh no! Our movie magic is having a bad day.",
  "Houston, we have a problem. But don't worry, we're on it!",
  "This page escaped! We'll have it back in a jiffy.",
  "Well, this is embarrassing. Even the best movies have bloopers!"
];

const Error: React.FC<ErrorProps> = ({ onRetry }) => {
  const [dynamicMessage, setDynamicMessage] = useState<string | null>(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * funnyErrorMessages.length);
    setDynamicMessage(funnyErrorMessages[randomIndex]);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-5 text-red-600">
      <p className="text-lg text-center">Oops: {dynamicMessage}</p>
      {onRetry && (
        <button
          onClick={onRetry}
          className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
        >
          Retry
        </button>
      )}
    </div>
  );
};

export default Error;
