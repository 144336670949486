import React, { useEffect, useState } from 'react';

const moviePhrases = [
  "Reticulating splines... wait, wrong script!",
  "Reeling in the film...",
  "Casting the actors for your page...",
  "Building sets... Please hold your popcorn!",
  "Gathering all the movie stars...",
  "Cue the lights! Almost there...",
  "Holding for the applause... Just a moment!",
  "Adjusting our lenses to focus just right...",
  "Finding someone to blame for the delay...",
  "And... Action! Well, in just a bit.",
  "Editing the final cuts... Almost ready!",
  "Securing the red carpet for your entrance..."
];

const Loading: React.FC<{ message?: string }> = ({ message }) => {
  const [dynamicMessage, setDynamicMessage] = useState<string>(message || '');

  useEffect(() => {
    if (!message) {
      const randomIndex = Math.floor(Math.random() * moviePhrases.length);
      setDynamicMessage(moviePhrases[randomIndex]);
    }
  }, [message]);

  return (
    <div className="flex flex-col items-center justify-center p-5">
      <div role="status" className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      <p className="text-lg mt-3 text-gray-600">{dynamicMessage}</p>
    </div>
  );
};

export default Loading;
