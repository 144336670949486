import { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading';
import { HOME, MOVIE_DETAIL, SEARCH } from './constants/routePaths';

const MainRoute = lazy(() => import('./pages/MainRoute'));
const MovieDetail = lazy(() => import('./components/MovieDetail'));
const SearchResults = lazy(() => import('./components/SearchResults'));

const App: React.FC = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={HOME} element={<MainRoute />} />
            <Route path={MOVIE_DETAIL} element={<MovieDetail />} />
            <Route path={SEARCH} element={<SearchResults />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
